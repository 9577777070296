<template>
  <div>
    <!-- 零售门店排行 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" :rank="rank" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10">图表类型</div>
            <el-radio-group v-model="chartTypes" size="mini" @change="draw">
              <el-radio label="zzt">柱状图</el-radio>
              <el-radio label="tzt">饼图</el-radio>
            </el-radio-group>
          </div>
          <div class="sep-t" ref="chart" style="height:400px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <!-- 表格 -->
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.rank" class="padding-05 sep-b">
            <div class="fs-large bold padding-05">排名{{ o.rank }}，{{ o.name }}</div>
            <template v-if="isReported">
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  销售金额：{{ $price(o.realCheckSumMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData &&
                  o.compareYearData.realCheckSumMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.realCheckSumMoneyRatio
                  )
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  销售数量：{{ o.checkSaleNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSaleNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSaleNumberRatio
                  )
                  }}
                </div>

                <div class="padding-05" style="width: 50%">
                  发货金额：{{ $price(o.checkSendMoney) }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSendMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSendMoneyRatio
                  )
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货数量：{{ o.checkSendNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.checkSendNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(
                  o.compMonthData && o.compMonthData.checkSendNumberRatio
                  )
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  销售金额：{{ $price(o.realSumMoney) || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.realSumMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.realSumMoneyRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  销售数量：{{ o.saleNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.saleNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.saleNumberRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货金额：{{ $price(o.sendMoney) || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.sendMoneyRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.sendMoneyRatio)
                  }}
                </div>
                <div class="padding-05" style="width: 50%">
                  发货数量：{{ o.sendNumber || "-" }}
                  <br />
                  同比增长率：{{
                  $percent(
                  o.compareYearData && o.compareYearData.sendNumberRatio
                  )
                  }}
                  <br />
                  环比增长率：{{
                  $percent(o.compMonthData && o.compMonthData.sendNumberRatio)
                  }}
                </div>
              </div>
            </template>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/retail";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [], //图表数据
      isReported: false,
      chartTypes: "zzt",
      chart: null,
      showOpt: {
        showGoodsType: true, //商品分类
        showDimension: false, //分析维度隐藏
        showRangGoods: true, //商品范围
        showCommodity: true, //指定商品
        showRank: true, //显示排名对象
        showReported: true, //仅显示已上报
        showYearonyear: true, //同比显示
        showMonth: true, //环比显示
        showRankGist: true, //显示排名依据
        showLevel: false, //汇总级别
        showCompareyear: false, //同比年度
        showTimeRange: true, //时间范围
        showDetail: false, //显示零售明细查询
      },
      query: {
        addressType: "area",
        dateGroup: "day",
        area: {
          treeNodeId: "1",
          treeNodeType: "group",
        },
        standard: {
          treeNodeId: "",
          StandardAreaGroup: "country", //标准行政区域
        },
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        brandId: null, //商品范围 品牌
        seriesId: null, //商品范围 系列
        categoryId: null, //商品范围 分类
        goods: null, //指定商品

        reportOnly: false, //只统计已上报
        compareYear: new Date(
          now.getFullYear() - 1,
          now.getMonth() - 1,
          now.getDate()
        ).getTime(), //同比年度
        yearOnYearShow: false, //同比
        compareMonth: false, //环比
        saleValueGroup: "realSumMoney", //排名依据默认销售金额
        AreaGroupType: "saleValueGroup", //排名对象
        shopAreaGroup: "area", //排名对象
        showCategory: false, //对比类型
        page: 0,
        size: 10000,
      },
      rank: {
        rankDefines: [
          {
            name: "区域",
            value: { AreaGroupType: "area", shopAreaGroup: "area" },
          },
          {
            name: "经销商",
            value: { AreaGroupType: "area", shopAreaGroup: "distributor" },
          },
          {
            name: "省",
            value: { AreaGroupType: "standard", cityAreaGroup: "province" },
          },
          {
            name: "市",
            value: { AreaGroupType: "standard", cityAreaGroup: "city" },
          },
        ],
        multParams: 0,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (!data.reportOnly) delete data.reportOnly;
      Object.assign(data, this.rank.rankDefines[this.rank.multParams].value);
      let sd = new Date(data.compareYear);
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();

      if (data.addressType == "standard") {
        Object.assign(data, data.standard);
      } else {
        Object.assign(data, data.area);
      }
      delete data.standard;
      delete data.area;

      if (this.query.yearOnYearShow == false) {
        delete data.compareYear;
      } else {
        data.compareYear = sd.getFullYear();
      }
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },

    loadEchart() {
      if (this.query.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      if (this.query.yearOnYearShow == false) {
        request({
          url: "@host:analysis;api/analy/retail/sale/rank",
          method: "get",
          params: data,
        })
          .then((res) => {
            this.store = res.content;
            this.totalElements = res.totalElements;
            this.$nextTick((_) => {
              this.draw();
            });
          })
          .catch((err) => {
            this.store = [];
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.query.yearOnYearShow == true) {
        request({
          url: "@host:analysis;api/analy/retail/sale/rank",
          method: "get",
          params: data,
        })
          .then((res) => {
            this.store = res.content;
            this.totalElements = res.totalElements;
            this.$nextTick((_) => {
              this.draw();
            });
          })
          .catch((err) => {
            this.store = [];
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    draw() {
      if (this.store && this.store.length) {
        let sourceName = []; //x轴
        let sourceData1 = []; //系列1数据
        let sourceData2 = []; //系列2数据
        let sourceData3 = []; //系列3数据
        let binChart = []; //系列3数据
        let binChart2 = [];
        let binChart3 = [];
        let svgp = [];
        let groupRankName = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          if (o.name) {
            sourceName.push(o.name);
          } else {
            sourceName.push("不详");
          }

          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            sourceData1.push(o[this.query.saleValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            legendsvgp.push("已上报销售金额");
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            svgp.push("单品数量");
            break;
          case "checkSaleNumber":
            legendsvgp.push("已上报单品数量");
            svgp.push("已上报单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            legendsvgp.push("已上报发货金额");
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            legendsvgp.push("已上报发货数量");
            svgp.push("已上报发货数量");
            break;
        }
        switch (this.query.AreaGroupType) {
          case "saleValueGroup":
            groupRankName.push("区域排名");
            break;
          case "shopAreaGroup":
            groupRankName.push("店名排名");
            break;
        }
        if (this.query.yearOnYearShow == true) {
          legendsvgp.push("同比");
          (this.store || []).forEach((o) => {
            if (o.compareYearData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData2.push(o.compareYearData[this.query.saleValueGroup]);
                binChart2.push({
                  name: o.name,
                  value: o.compareYearData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData2.push(
                  Math.round(
                    o.compareYearData[this.query.saleValueGroup] *
                      0.000001 *
                      100
                  ) / 100
                );
                binChart2.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compareYearData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }
        if (this.query.compareMonth == true) {
          legendsvgp.push("环比");
          (this.store || []).forEach((o) => {
            if (o.compMonthData) {
              if (
                this.query.saleValueGroup == "saleNumber" ||
                this.query.saleValueGroup == "checkSaleNumber" ||
                this.query.saleValueGroup == "sendNumber" ||
                this.query.saleValueGroup == "checkSendNumber"
              ) {
                sourceData3.push(o.compMonthData[this.query.saleValueGroup]);
                binChart3.push({
                  name: o.name,
                  value: o.compMonthData[this.query.saleValueGroup],
                });
                yAxisName = "数量";
              } else {
                sourceData3.push(
                  Math.round(
                    o.compMonthData[this.query.saleValueGroup] * 0.000001 * 100
                  ) / 100
                );
                binChart3.push({
                  name: o.name,
                  value:
                    Math.round(
                      o.compMonthData[this.query.saleValueGroup] *
                        0.000001 *
                        100
                    ) / 100,
                });
                yAxisName = "金额（万元）";
              }
            }
          });
        }

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        // 处理饼状图数据
        binChart = (this.store || []).map((o) => {
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            return {
              name: o.name ? o.name : "不详",
              value: o[this.query.saleValueGroup],
            };
          } else {
            return {
              name: o.name ? o.name : "不详",
              value: o[this.query.saleValueGroup].toFixed(2) / 100,
            };
          }
        });
        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            legend: {
              data: legendsvgp,
            },
            tooltip: {
              trigger: "axis",
              confine: true,
            },
            calculable: true,
            grid: {
              left: 50,
              right: 20,
              bottom: 50,
              top: 50,
            },
            xAxis: [
              {
                type: "category",
                data: sourceName,
                axisLabel: {
                  formatter: function (value) {
                    if (value.length > 5) {
                      return value.substr(0, 5) + "…";
                    }
                    return value;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                    },
                  },
                },
              },
              sourceData2.length > 0
                ? {
                    name: "同比",
                    type: "bar",
                    data: sourceData2,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
              sourceData3.length > 0
                ? {
                    name: "环比",
                    type: "bar",
                    data: sourceData3,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "top",
                        },
                      },
                    },
                  }
                : "",
            ],
            dataZoom: {
              show: sourceName.length > 5, // 为true 滚动条出现
              filterMode: "weakFilter",
              type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
              height: 15,
              start: 0,
              end: (5 / sourceName.length) * 100,
              bottom: 10,
              handleIcon:
                "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              zoomLock: true,
              showDetail: false,
            },
          });
        } else if (this.chartTypes == "tzt") {
          //饼状图
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light");
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              left: "center",
              top: 20,
            },
            tooltip: {
              trigger: "item",
              confine: true,
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp + groupRankName,
                type: "pie",
                radius: "30%",
                center: ["50%", "18%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "同比分析",
                type: "pie",
                radius: "30%",
                center: ["50%", "50%"],
                data: binChart2.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
              {
                name: svgp + groupRankName + "环比分析",
                type: "pie",
                radius: "30%",
                center: ["50%", "80%"],
                data: binChart3.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },
  },
};
</script>